import './assets/main.scss'

import { createSSRApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import VueGtag from 'vue-gtag'

export function createApp() {
  const app = createSSRApp(App)
  const pinia = createPinia()
  app.use(pinia)
  app.use(router)
  app.use(VueGtag, {
    config: {
      id: 'G-RZ36NL7YN3'
    },
    appName: 'Neuromandic',
    pageTrackerScreenviewEnabled: true
  }, router)
  return { app }
}
