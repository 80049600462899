<script setup lang="ts">

</script>

<template>
  <div class="header__title">
    <img src="../../assets/images/neuromandic_logo.svg" alt="NEUROMANDIC"/>
  </div>
  <div class="header__actions">
      <router-link to="/" class="link-button" active-class="active">O MNIE</router-link>
      <router-link to="/blog" class="link-button" active-class="active">BLOG</router-link>
      <router-link to="/oferta" class="link-button" active-class="active">OFERTA</router-link>
      <router-link to="/kontakt" class="link-button" active-class="active">KONTAKT</router-link>
  </div>
</template>

<style scoped lang="scss">
.header__actions {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

img {
  width: 300px;
  height: auto;
  transform: translateX(-17px);
}

h1 {
  margin: 0;
  font-weight: 400;
  letter-spacing: 4px;
  line-height: 1.4;
}

h2 {
  font-size: .75rem;
  margin: 0;
  text-align: center;
  font-weight: 400;
  letter-spacing: 1px;
}



@media (max-width: 420px) {

  img {
    transform: none;
  }

  .header__details {
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
  }

  .link-button {
    font-size: 0.85rem;
  }

  .header__actions {
    gap: 1rem;
  }
}
</style>