<script setup lang="ts">

</script>

<template>
  <div class="footer-container">
    <div class="footer__logo">
      <img src="@/assets/images/footer_logo.svg" alt="Neuromandic">
    </div>
    <div class="footer__contact">
      <a href="mailto:logopeda@neuromandic.pl">logopeda@neuromandic.pl</a>
      <a href="tel:+48455401483">+48 455 401 483</a>
    </div>
    <div class="footer__social">
      <a href="https://www.facebook.com/neuromandic.logopeda" target="_blank">
        <img class="link-image-sm" src="@/assets/images/facebook.svg" alt="Facebook">
      </a>
      <a href="https://www.linkedin.com/in/magdalena-czy%C5%BCak-logopeda/" target="_blank">
        <img class="link-image-sm" src="@/assets/images/linkedin.svg" alt="LinkedIn">
      </a>
    </div>
  </div>
  <div class="footer__bottom">
      <span class="footer__bottom-copyright">
        &copy; 2024 Neuromandic
      </span>
  </div>
</template>

<style scoped lang="scss">
.footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 1rem;
  gap: 1rem;
}

.footer__bottom {
  width: 100%;
  text-align: center;

  span {
    color: $primary-1-color;
  }

  .footer__bottom-copyright {
    white-space: nowrap;
  }
}

.footer__contact {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  a {
    font-weight: 500;
    text-decoration: none;
    color: $primary-1-color;
  }
}

.footer__social {
  display: flex;
  gap: 1rem;
  margin-left: auto;
}

.link-image-sm {
  width: 30px;
}

.footer__logo {
  img {
    width: 56px;
    height: auto;
  }
}

@media (max-width: 1200px) {
  .footer__logo img{
    padding-left: 1rem;
  }

  .footer__social {
    padding-right: 1rem;
  }
}

@media (max-width: 420px) {
  .footer-container {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    text-align: center;
    font-size: 0.85rem;
  }

  .footer__social {
    margin-left: 0;
    padding-right:0;
  }

  .footer__logo img {
    padding-left: 0;
    padding-right:1rem;
  }
}
</style>
