import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '',
    name: 'Home',
    component: () => import('@/pages/HomePage/index.vue')
  },
  {
    path: '/oferta',
    name: 'Oferta',
    component: () => import('@/pages/ServicesPage/index.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('@/pages/BlogPage/index.vue')
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import('@/pages/ContactPage/index.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: () => import('@/pages/NotFound/index.vue')
  },
]

// Determine if we're in a server environment
const isServer = typeof window === 'undefined'

// Use createMemoryHistory for SSR and createWebHistory for client-side
const history = isServer ? createMemoryHistory() : createWebHistory()

const router = createRouter({
  history,
  routes
})

export default router
